import React from 'react';

export default function CoalitionText(props) {
    function selectColor() {

        switch (props.children.toUpperCase()) {
            case "RED":
                return 'red';
            case "BLUE":
                return 'blue';
            default:
                return 'black';
        }
    }

    return (
        <span style={{ color: selectColor()}}>
            {props.children}
        </span>
    )
}