import React, { useEffect, useState } from 'react';
import './Players.css'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const aircraftCode = {
  'A-10A': "A-10A",
  'A-10C_2': "A-10C",
  'F-16C_50': "F-16C",
  'F-15C': "F-15C",
  'FA-18C_hornet': "FA-18C",
  'Su-25T': "Su-25T",
  'Mi-8MT': "Mi-8",
  'AJS37': "AJS-37",
  'AV8BNA': "AV-8B",
  'F-14B': "F-14B",
  'Ka-50': "Ka-50",
  'M-2000C': "M-2000C",
  'Mi-24P': "Mi-24P",
  'SA342Mistral': "SA342",
  'Su-27': "Su-27",
  'UH-1H': "UH-1H",
  'MiG-29S': "MiG-29S"
}
export default function Players(props) {
  var [clients, setClients] = useState([]);

  useEffect(() => {
    setClients(props.clients.filter(client => {
      return props.coalition === null || client.coalition === props.coalition
    }))
  }, [props.clients, props.coalition])

  function selectColor(coalition) {

    switch (coalition) {
      case 1:
        return 'red';
      case 2:
        return 'blue';
      default:
        return ;
    }
  }

  return (
    <>
      {
        props.title && <h3 style={{ color: selectColor(parseInt(props.coalition)) }}>{props.title}</h3>
      }
      {
        (clients.length !== 0 &&
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '50%' }}>Clients</TableCell>
              <TableCell style={{ width: '40%' , 'textAlign': 'center'}}>Aircraft</TableCell>
              <TableCell style={{ width: '10%' , 'textAlign': 'center'}}>Total Points</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              clients.map(client => (
                <TableRow key={client.ucid}>
                  <TableCell>
                    <span className="player" style={{ color: selectColor(client.coalition) }}>{client.name}</span>
                    <div>
                      {
                        client.radios != null && client.radios.map((radio, key) => (
                          <span key={key} className="radio" >
                            {(radio / 1000000.0).toFixed(3)}
                          </span>
                        ))
                      }
                    </div>
                  </TableCell>
                  <TableCell style={{ 'textAlign': 'center' }}>{aircraftCode[client.unit] || client.unit}</TableCell>
                  <TableCell style={{ 'textAlign': 'center' }}>{client.totalPoints}</TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table >
        ) || <span>None</span>
      }
    </>
  )
}
