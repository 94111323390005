import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import { wsSubscribe, wsUnsubscribe } from '../services/clients';
import { Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';

const Rules = (props) => {
    var [rules, setRules] = useState([]);
    useEffect(() => {
        var sub = wsSubscribe("rules", setRules)

        return () => {
            wsUnsubscribe(sub)
        }
    }, [])

    return (
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <h1>
                Rules
            </h1>
            {
                rules.map(e => (
                    <div key={e.id} >
                        {e.content.split("\n").map((t, index) => (
                            <Typography key={index}>
                                <ReactMarkdown key={index} components={{
                                    p: React.Fragment,
                                }}>
                                    {t}
                                </ReactMarkdown>
                                <br />
                            </Typography>
                        ))}
                    </div>
                ))
            }
            <div>

            </div>
        </Paper>
    )
}

export default Rules