import React, { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { useMediaQuery } from '@mui/material';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { wsSubscribe, wsUnsubscribe } from '../services/clients';

import Footer from './Footer';
import TopBar from './TopBar';
import Menu from './Menu';
import Dashboard from '../pages/Dashboard';
import Rules from '../pages/Rules';
import News from '../pages/News';

const drawerWidth = 300;
var darkMode = Boolean(localStorage.getItem('darkMode') === "true")
var menuClosed = Boolean(localStorage.getItem('menuClosed') === "true")

export default function Layout(props) {
    const [mdTheme, setMdTheme] = useState(createTheme({
        palette: {
            mode: darkMode ? 'dark' : 'light'
        },
    }))

    const toggleTheme = () => {
        darkMode = !darkMode
        setMdTheme(
            createTheme({
                palette: {
                    mode: darkMode ? 'dark' : 'light'
                },
            }))
        localStorage.setItem('darkMode', darkMode)
    }

    const small = useMediaQuery(mdTheme.breakpoints.down('md'));

    const [open, setOpen] = useState(!small && !menuClosed);
    const toggleDrawer = () => {
        setOpen(!open);
        if (!small) {
            localStorage.setItem('menuClosed', open)
        }
    };

    useEffect(() => {
        setOpen(!small && !menuClosed)
    }, [small, menuClosed]);

    var [news, setNews] = useState([]);
    useEffect(() => {
        var sub = wsSubscribe("news", setNews)

        return () => {
            wsUnsubscribe(sub)
        }
    }, [])

    var [lastNotification, setLastNotification] = useState(parseInt(localStorage.getItem('lastNotification')) || 0);
    const getUnreadNotifications = () => {
        return news?.filter((n) => { return n.id > lastNotification }).length;
    }

    var [unreadNotifications, setUnreadNotifications] = useState(getUnreadNotifications());

    useEffect(() => {
        localStorage.setItem('lastNotification', lastNotification)
        setUnreadNotifications(getUnreadNotifications())
    }, [news, lastNotification])

console.log(lastNotification)

    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <BrowserRouter>
                    <TopBar drawerWidth={drawerWidth} open={open} toggleDrawer={toggleDrawer} small={small} toggleTheme={toggleTheme} news={news} 
                        unreadNotifications={unreadNotifications} setLastNotification={setLastNotification}/>
                    <Menu drawerWidth={drawerWidth} open={open} toggleDrawer={toggleDrawer} small={small} />
                    <Box
                        component="main"
                        sx={{
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'light'
                                    ? theme.palette.grey[100]
                                    : theme.palette.grey[900],
                            flexGrow: 1,
                            height: '100vh',
                            overflow: 'auto',
                        }}
                    >
                        <Toolbar />
                        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                            <Routes>
                                <Route path="/" element={<Dashboard />} />
                                <Route path="/dashboard" element={<Dashboard />} />
                                <Route path="/rules" element={<Rules />} />
                                <Route path="/news" element={<News news={news} setLastReadNotification={setLastNotification} />} />
                            </Routes>
                            <Footer sx={{ pt: 4 }} />
                        </Container>
                    </Box>
                </BrowserRouter>
            </Box>
        </ThemeProvider>
    )
}
