import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import RuleIcon from '@mui/icons-material/Rule';
import { Link } from "react-router-dom";
import LaunchIcon from '@mui/icons-material/Launch';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import YouTube from '@mui/icons-material/YouTube';

const DISCORD_URL = process.env.REACT_APP_DISCORD_URL
const YOUTUBE_URL = process.env.REACT_APP_YOUTUBE_URL

export const mainListItems = (
  <div>
    <ListItem button component={Link} to="/dashboard">
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Server" />
    </ListItem>
    <ListItem button component={Link} to="/news">
      <ListItemIcon>
        <NewspaperIcon />
      </ListItemIcon>
      <ListItemText primary="News" />
    </ListItem>
    <ListItem button component={Link} to="/rules">
      <ListItemIcon>
        <RuleIcon />
      </ListItemIcon>
      <ListItemText primary="Rules" />
    </ListItem>
    <ListItem button component="a" href={DISCORD_URL} target="_blank">
      <ListItemIcon>
        <span className='material-icons'>discord</span>
      </ListItemIcon>
      <ListItemText primary="Discord" />
      <LaunchIcon fontSize='small' />
    </ListItem>
    <ListItem button component="a" href={YOUTUBE_URL} target="_blank">
      <ListItemIcon>
        <YouTube />
      </ListItemIcon>
      <ListItemText primary="YouTube" />
      <LaunchIcon fontSize='small' />
    </ListItem>
  </div>
);
