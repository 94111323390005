import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import { ClickAwayListener, Collapse } from '@mui/material';
import { Box } from '@mui/system';
import News from '../pages/News';
import Popper from '@mui/material/Popper';
import IconButton from '@mui/material/IconButton';

const NotificationsPopper = styled(Popper, {
    shouldForwardProp: (prop) => !['drawerWidth', 'small',].includes(prop),
})(({ theme, small }) => ({
    zIndex: theme.zIndex.drawer + 1,
    width: small ? '80%' : '700px',
    maxHeight: '80%',
    overflowY: 'auto',
}));

const Notification = ({small, news, unreadNotifications, setLastNotification}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openNotifications, setOpenNotifications] = React.useState(false);

    const handleNotificationsClick = (event) => {
        setAnchorEl(document.getElementById("topbar"));
        setOpenNotifications(!openNotifications)
        if (news && news.length > 0) {
            setLastNotification(news[0].id)
        }
    };

    return (
        <ClickAwayListener onClickAway={() => setOpenNotifications(false)}>
            <Box>
                <IconButton color="inherit" onClick={handleNotificationsClick}>
                    <Badge badgeContent={unreadNotifications} color="error">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <NotificationsPopper open={openNotifications} anchorEl={anchorEl} placement='bottom-end' small={small} transition keepMounted>
                    {({ TransitionProps }) => (
                        <Collapse {...TransitionProps} timeout={350}>
                            <News news={news} />
                        </Collapse>
                    )}
                </NotificationsPopper>
            </Box>
        </ClickAwayListener>
    )
}

export default Notification;