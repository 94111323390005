import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import { Fragment } from "react"
import { Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';

const News = ({ news, setLastReadNotification }) => {
    if (setLastReadNotification) {
        if (news && news.length > 0) {
            setLastReadNotification(news[0].id)
        }
    }

    return (
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <h1>
                News
            </h1>
            {
                news?.map(e => (
                    <Paper key={e.id} sx={{ p: 2, display: 'flex', flexDirection: 'column', marginBottom: '15px' }} variant="outlined">
                        {e.content.split("\n").map((t, index) => (
                            <Typography key={index}>
                                <ReactMarkdown components={{
                                    p: React.Fragment,
                                }}>
                                    {t}
                                </ReactMarkdown>
                                <br />
                            </Typography>
                        ))}
                        <Typography align='right' sx={{ opacity: '0.4' }}>
                            {new Date(e.timestamp).toLocaleString()}
                        </Typography>
                    </Paper>
                ))
            }
            <div>

            </div>
        </Paper>
    )
}

export default News