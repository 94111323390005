import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import Notification from '../components/Notification';

const DISCORD_URL = process.env.REACT_APP_DISCORD_URL

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => !['drawerWidth', 'small', 'open',].includes(prop),
})(({ theme, open, drawerWidth, small }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...((!small && open) && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    ...((small && open) && {
        zIndex: theme.zIndex.drawer - 1,
    }),
}));

const TopBar = ({ drawerWidth, open, toggleDrawer, small, toggleTheme, news, unreadNotifications, setLastNotification }) => {
    return (
        < AppBar position="absolute" drawerWidth={drawerWidth} open={open} small={small} >
            <Toolbar id="topbar"
                sx={{
                    pr: '24px', // keep right padding when drawer closed
                }}
            >
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleDrawer}
                    sx={{
                        marginRight: '36px',
                        ...((!small && open) && { display: 'none' }),
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{ flexGrow: 1 }}
                >
                    FDS Server - Dashboard
                </Typography>
                <IconButton color="inherit" onClick={toggleTheme}>
                    <DarkModeIcon />
                </IconButton>
                <IconButton color="inherit" href={DISCORD_URL} target="_blank">
                    <span className='material-icons'>discord</span>
                </IconButton>
                <Notification small={small} news={news} unreadNotifications={unreadNotifications} setLastNotification={setLastNotification} />
            </Toolbar>
        </AppBar >
    )
}

export default TopBar