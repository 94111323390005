import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { wsSubscribe, wsUnsubscribe } from '../services/clients';

export default function Server(props) {
    var [serverInfo, setServerInfo] = useState({ status: "" });

    useEffect(() => {
        var sub = wsSubscribe("server", setServerInfo)

        return () => {
            wsUnsubscribe(sub)
        }
    }, [])

    const getStatusColor = () => {
        var status = serverInfo.status.toUpperCase();
        
        switch (status) {
            case 'RUNNING':
                return "green"
            case 'PAUSED':
                return "goldenrod"
            case 'STOPPED':
                return "red"
            default:
                return "black"
        }
    }

    const getStatusText = () => {
        var status = serverInfo.status.toUpperCase();
        switch (status) {
            case 'PAUSED':
                return "WAITING FOR PLAYERS"
            default:
                return status
        }
    }

    return (
        <>
            <h3>Server Info</h3>
            <Grid container>
                <Grid item xs={12} md={4}>
                    <Box sx={{ fontWeight: 'bold', display: 'inline' }}>Mission time: </Box>
                    <Box sx={{ fontWeight: 'regular', display: 'inline' }}>{serverInfo.current}</Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box sx={{ fontWeight: 'bold', display: 'inline' }}>Time since start: </Box>
                    <Box sx={{ fontWeight: 'regular', display: 'inline' }}>{serverInfo.elapsed}</Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box sx={{ fontWeight: 'bold', display: 'inline' }}>Server status: </Box>
                    <Box sx={{ fontWeight: 'regular', display: 'inline', color: getStatusColor() }}>{getStatusText()}</Box>
                </Grid>
            </Grid>
        </>
    )
}