import { fontSize } from "@mui/system";
import React from "react";

const ProgressBar = (props) => {
    const { bgcolor, completed, contW, contH } = props;
    const containerStyles = {
      height: contH,
      width: contW,
      backgroundColor: "#e0e0de",
      borderRadius: 50,
      margin: '0.5%'
    }
  
    const fillerStyles = {
      height: '100%',
      width: `${completed}%`,
      backgroundColor: bgcolor,
      borderRadius: 'inherit',
      transition: 'width 1s ease-in-out',
      textAlign: 'right'
    }
  
    const labelStyles = {
      padding: 5,
      color: 'white',
      fontWeight: 'bold',
      fontSize: 16
    }
  
    return (
      <div style={containerStyles}>
        <div style={fillerStyles}>
          <span style={labelStyles}></span>
        </div>
      </div>
    );
};
  
export default ProgressBar;