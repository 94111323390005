import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import { wsSubscribe, wsUnsubscribe } from '../services/clients';
import ProgressBar from "./progressBar.js";

export default function Server(props) {
    var [mission, setMission] = useState({ blue: { dropTime: "-" }, red: {} });

    useEffect(() => {
        var sub = wsSubscribe("mission_status", setMission)

        return () => {
            wsUnsubscribe(sub)
        }
    }, [])
    return (
        <>
            <h3>Mission Info</h3>
            <Grid container>
                <Grid item xs={12} md={12}>
                    <Grid container>
                        <Grid item xs={12} md={4}>
                            <Box sx={{ fontWeight: 'bold', display: 'inline' }}>Minutes until the next air supply: </Box>
                            <Box sx={{ fontWeight: 'regular', display: 'inline' }}>{mission?.blue.dropTime} min</Box>
                        </Grid>
                        <Grid item xs={11} md={7}>
                            <Grid container>
                                <Grid item>
                                    <Box sx={{ fontWeight: 'bold', display: 'inline' }}>Start</Box>
                                </Grid>
                                <Grid item xs={7} md={9}>
                                    <ProgressBar bgcolor={"#02752F"} completed={100.0 - Math.floor(100 * mission?.blue.dropTime / 45)} contW={"95%"} contH={15} />
                                </Grid>
                                <Grid item>
                                    <Box sx={{ fontWeight: 'bold', display: 'inline' }}>Delivery</Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container>
                        <Grid item>
                            <img src={process.env.PUBLIC_URL + '/icons/cargoSymb_Mini.webp'} width="65" height="44" />
                        </Grid>
                        <Grid item md={4}>
                            <Box sx={{ fontWeight: 'bold', display: 'inline' }}>Flying cargo planes:</Box>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Box sx={{ fontWeight: 'bold', display: 'inline', color: 'blue' }}>Blue: </Box>
                                    <Box sx={{ fontWeight: 'regular', display: 'inline' }}>{mission?.blue.transportSquad}</Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box sx={{ fontWeight: 'bold', display: 'inline', color: 'red' }}>Red: </Box>
                                    <Box sx={{ fontWeight: 'regular', display: 'inline' }}>{mission?.red.transportSquad}</Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container>
                        <Grid item>
                            <img src={process.env.PUBLIC_URL + '/icons/bomberSymb_Mini.webp'} width="65" height="44" />
                        </Grid>
                        <Grid item md={4}>
                            <Box sx={{ fontWeight: 'bold', display: 'inline' }}>Total bombing runs:</Box>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Box sx={{ fontWeight: 'bold', display: 'inline', color: 'blue' }}>Blue: </Box>
                                    <Box sx={{ fontWeight: 'regular', display: 'inline' }}>{mission?.blue.bombers}</Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box sx={{ fontWeight: 'bold', display: 'inline', color: 'red' }}>Red: </Box>
                                    <Box sx={{ fontWeight: 'regular', display: 'inline' }}>{mission?.red.bombers}</Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}
