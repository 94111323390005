import ReconnectingWebSocket from "reconnecting-websocket";

const STATS_V1 = '/stats/v1'
const API_URL_V1 = process.env.REACT_APP_API_URL + STATS_V1;

var absoluteRegex = new RegExp('^(?:[a-z]+:)?//', 'i');

const WS_URL_V1 = absoluteRegex.test(process.env.REACT_APP_WS_URL)
    ? process.env.REACT_APP_WS_URL + STATS_V1
    : ((window.location.protocol === "https:") ? "wss://" : "ws://") + window.location.host + process.env.REACT_APP_WS_URL + STATS_V1

const socket = new ReconnectingWebSocket(WS_URL_V1 + "/ws");

export function wsSubscribe(topic, cb) {
    if (socket.readyState === ReconnectingWebSocket.OPEN) {
        socket.send('{"command": "subscribe","topic": "' + topic + '"}')
    }
    var openListener = socket.addEventListener("open", e => {
        socket.send('{"command": "subscribe","topic": "' + topic + '"}')
    })
    var messageListener = socket.addEventListener("message", (e) => {
        var data = JSON.parse(e.data)
        if (data.topic === topic) {
            cb(data.message)
        }
    })
    return {
        'topic': topic,
        'openListener': openListener,
        'messageListener': messageListener
    }
}

export function wsUnsubscribe(sub) {
    if (socket.readyState === 1) {
        socket.send('{"command": "unsubscribe","topic": "' + sub.topic + '"}')
    }
    socket.removeEventListener('open', sub.openListener)
    socket.removeEventListener('message', sub.messageListener)
}

export function getClients() {
    return fetch(API_URL_V1.concat('/clients'))
        .then(data => data.json())
}

export function getPlayers() {
    return fetch(API_URL_V1.concat('/players'))
        .then(data => data.json())
}

export function getServer() {
    return fetch(API_URL_V1.concat('/server'))
        .then(data => data.json())
}

export function getMissionFdsWars() {
    return fetch(API_URL_V1.concat('/mission/fds-wars'))
        .then(data => data.json())
}

export function getEntities() {
    return fetch(API_URL_V1.concat('/entities'))
        .then(data => data.json())
}