import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Players from '../components/Players'
import Server from '../components/Server'
import MissionFdsWars from '../components/MissionFdsWars'
import ZoneInfo from '../components/ZoneInfo'
import { wsSubscribe, wsUnsubscribe } from '../services/clients';

export default function Dashboard() {
    var [clients, setClients] = useState([]);
    useEffect(() => {
        var sub = wsSubscribe("players", setClients)

        return () => {
            wsUnsubscribe(sub)
        }
    }, [])

    var [entities, setEntities] = React.useState({ blue: [], red: [] });
    useEffect(() => {
        var sub = wsSubscribe("entities", setEntities)

        return () => {
            wsUnsubscribe(sub)
        }
    }, [])

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} style={{ display: 'inline-block' }}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <Server />
                </Paper>
            </Grid>
            <Grid item xs={12} style={{ display: 'inline-block' }}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <MissionFdsWars />
                </Paper>
            </Grid>
            <Grid item xs={12} style={{ display: 'inline-block' }}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <h3>Current Zone Integrity</h3>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} style={{ display: 'inline-block' }}>
                            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }} variant="outlined">
                                <ZoneInfo title="Blue" entities={entities?.blue} />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6} style={{ display: 'inline-block' }}>
                            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }} variant="outlined">
                                <ZoneInfo title="Red" entities={entities?.red} />
                            </Paper>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12} style={{ display: 'inline-block' }}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <h3>Current Players</h3>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} style={{ display: 'inline-block' }}>
                            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }} variant="outlined">
                                <Players title="Blue" coalition={2} clients={clients} />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6} style={{ display: 'inline-block' }}>
                            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }} variant="outlined">
                                <Players title="Red" coalition={1} clients={clients} />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} style={{ display: 'inline-block' }}>
                            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }} variant="outlined">
                                <Players title="Spectators" coalition={0} clients={clients} />
                            </Paper>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}
