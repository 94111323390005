import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CoalitionText from './CoalitionText';
import ProgressBar from "./progressBar.js";

const colorCode = { 'Red': "#D30000", 'Blue': "#0006D3"};
export default function ZoneInfo(props) {
    var [entities, setEntities] = useState([]);

    useEffect(() => {
        if (props.entities !== null)
            setEntities(props.entities)
    }, [props.entities])

    return (
        <>
            <h3><CoalitionText>{props.title}</CoalitionText></h3>
            <Grid container spacing={3}>
                {
                    entities?.map(entity => (
                        <Grid container item spacing={1} key={entity.name}>
                            <Grid item xs={12} md={12}>
                                <Box sx={{ fontWeight: 'bold', display: 'block' }}>{entity.name}</Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <img src={process.env.PUBLIC_URL + '/icons/structSymb_Mini.webp'} width="38" height="22"/>
                                <Box sx={{ fontWeight: 'regular', display: 'inline' }}>    Structures: {entity.remainingStructures} / {entity.totalStructures}</Box>
                                <ProgressBar bgcolor={colorCode[entity.name.split(' ')[0]]} completed={Math.floor(100*(entity.remainingStructures/entity.totalStructures))} contW={"80%"} contH={15}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <img src={process.env.PUBLIC_URL + '/icons/tankSymb_Mini.webp'} width="38" height="22"/>
                                <Box sx={{ fontWeight: 'regular', display: 'inline' }}>    Units: {entity.remainingUnits} / {entity.totalUnits}</Box>
                                <ProgressBar bgcolor={colorCode[entity.name.split(' ')[0]]} completed={Math.floor(100*entity.remainingUnits/entity.totalUnits)} contW={"80%"} contH={15}/>
                            </Grid>
                        </Grid>
                    ))
                }
            </Grid>
        </>
    )
}